<template>
    <div class='p-inputgroup'>
        <span class='p-float-label'>
            <InputText :id='id'
                       type='text'
                       :modelValue="modelValue"
                       @update:modelValue="emit($event)"

            />
            <ColorPicker :modelValue="modelValue"
                         @update:modelValue="cEmit($event)" />
            <label :for='id'>{{title}}</label>
        </span>
    </div>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        id: {
            type: String
        },
        modelValue: String,
        title: {
            type: String,
            default: "Scegli il colore"
        }
    },
    methods: {
        emit(v){
            this.$emit('update:modelValue', v);
        },
        cEmit(v){
            this.$emit('update:modelValue', "#"+v);
        }
    }
};
</script>
