import tools from '../../../libs/tools';
import { minLength, required } from '@vuelidate/validators';

const model = {
    alter_color: "#FFFFFF",
    id: 0,
    categories: [],
    last_update: 0,
    primary_color: "#FFFFFF",
    secondary_color: "#FFFFFF",
    slug: "",
    translations: {}
};
const translation = {
    id: 0,
    last_update: 0,
    slug: "",
    mc_id: 0,
    locale: "",
    title: "",
    claim: "",
    publish_state: "",
    description: "",
    seo_title: "",
    seo_description: ""
};



export default {
    ...tools.models.apiCommons(model, translation, "mc_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            categories: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            title: { required },
            publish_state: { required }
        }
    }
};
